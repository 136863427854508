'use strict';

var GetIntrinsic = require('get-intrinsic');
var $TypeError = GetIntrinsic('%TypeError%');
var GetV = require('./GetV');
var IsCallable = require('./IsCallable');
var IsPropertyKey = require('./IsPropertyKey');
var inspect = require('object-inspect');

// https://262.ecma-international.org/6.0/#sec-getmethod

module.exports = function GetMethod(O, P) {
  // 7.3.9.1
  if (!IsPropertyKey(P)) {
    throw new $TypeError('Assertion failed: IsPropertyKey(P) is not true');
  }

  // 7.3.9.2
  var func = GetV(O, P);

  // 7.3.9.4
  if (func == null) {
    return void 0;
  }

  // 7.3.9.5
  if (!IsCallable(func)) {
    throw new $TypeError(inspect(P) + ' is not a function: ' + inspect(func));
  }

  // 7.3.9.6
  return func;
};